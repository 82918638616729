import React from 'react';
import { Container, Row } from 'react-bootstrap';
import leaflet from '../images/Leaflet Pic.jpg'

const Probate = () => (
  <div>
    <h1>Probate</h1>
    <img src={leaflet} alt="Probate assistance" className='float-left img-fluid mr-3 rounded'></img>
    <br /><br />
    <p>If you are acting as the executor of a loved one’s Will, or they have died without leaving a Will, handling their estate can feel like a heavy burden.</p>
    <p>I pride myself on my caring, friendly, and professional approach, making sure that everything is dealt with thoroughly, efficiently, and sensitively. 
      I can assist with all responsibilities an executor must complete, tax implications, and inheritance. I can also provide advice and assistance if someone 
      has died intestate, i.e. without a Will.</p><br /><br />
      <p>Responsibilities I can help with:</p>
    <ul>
      <li>Considering the detail of the Will</li>
      <li>Helping to distribute/sell/dispose of personal effects</li>
      <li>Identifying the beneficiaries</li>
      <li>Obtaining the Grant of Representation/Probate</li>
      <li>Establishing what is included in the estate of the deceased, such as property, shares, investments, cash, valuable collections, etc.</li>
      <li>Dealing with Inheritance Tax</li>
      <li>Distributing the estate under the Will or rules of intestacy</li>
    </ul>
<h4>Probate explained</h4>
<p>Probate: in England and Wales probate is the legal and financial processes involved in dealing with the property, money, and possessions (called the assets) 
  of a person who has died.</p>
<p>If there is a Will? is it valid? Who is the executor or who has the authority to administer the estate of the person who has died? This must be established first.</p>
<p>Before the next of kin or executor named in the will can claim, transfer, sell, or distribute any of the deceased's assets they will probably have to apply for a grant of probate.</p>
<h4>What is a grant of probate?</h4>
<p>A grant of probate is a legal document that's sometimes needed to access bank accounts, sell assets, and settle debts after someone has died.</p>
  </div>
);

export default Probate;
