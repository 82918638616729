import React from 'react';
import Liz from '../images/Liz pic leaflet.jpg'
import '../App.css'

const Helping = () => (
  <div>
    <h1>Help for the elderly</h1>
    <br />
    <img src={Liz} alt="Helping the elderly" className='float-left img-fluid mr-3 rounded mr-5'></img>
    <br />
    <p>
    <ul className='ml-1'>
      <li>Smartphone help/lessons</li>
      <br />
      <li>Computer help</li>
      <br />
      <li>Communication with companies</li>
      <br />
      <li>Filing Paperwork</li>
      <br />
      <li>Organising</li>
    </ul></p>
    <br />
    <br />
    <p>One of my observations is that the changing world of technology has become obstructive for the elderly to complete simple tasks. 
"I'm here to help! If you or a loved one need assistance with any of the services I offer, please don't hesitate to get in touch. You can reach me by phone on 07786 080068, or by email at <a href="mailto:matt@bishopprobateassistance.co.uk">matt@bishopprobateassistance.co.uk.</a>
I currently have several customers who use this service. If references are required, I am happy to provide them.</p>
  </div>
);

export default Helping;
