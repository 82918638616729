// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
  <Container fluid='md' className='mt-1'>
  <footer className="bg-white text-dark py-4 mt-5 page-footer">
      <Row>
        <Col className="text-end">
          <p>&copy; 2024 Bishop Probate Assistance. All Rights Reserved.</p>
        </Col>
      </Row>
  </footer>
  </Container>
);

export default Footer;
