// src/components/Header.js
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../images/logo.jpg';

const Header = () => (
  <Container fluid='md' className='mt-1'>
  <Navbar collapseOnSelect bg="white" data-bs-theme="light" expand="lg" className='page-header' sticky='top'>
    <Navbar.Brand as={Link} to="/"><img src={logo} alt="logo" className='mt-50 pl-50'/></Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ms-auto">
        <Nav.Link eventKey="1" as={Link} to="/">Home</Nav.Link>
        <Nav.Link eventKey="2" as={Link} to="/helping">Helping</Nav.Link>
        <Nav.Link eventKey="3" as={Link} to="/lpoa">LPOA</Nav.Link>
        <Nav.Link eventKey="4" as={Link} to="/probate">Probate</Nav.Link>
        <Nav.Link eventKey="5" as={Link} to="/contact">Contact</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
  </Container>
);

export default Header;
