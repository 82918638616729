// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Helping from './pages/Helping';
import Lpoa from './pages/Lpoa';
import Probate from './pages/Probate';
import Contact from './pages/Contact';

const App = () => (
  <Router>
    <Layout>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/helping" element={<Helping/>} />
        <Route path="/lpoa" element={<Lpoa/>} />
        <Route path="/probate" element={<Probate/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
    </Layout>
  </Router>
);

export default App;
