// src/pages/Home.js
import React from 'react';
import Me from './../images/me_laptop.jpg';
import '../App.css'

const Home = () => (
  <div>
    <img src={Me} alt="Matthew Bishop" className='float-left img-fluid mr-5 rounded'></img>
    <p>Bishop Probate Assistance is a Henfield-based company that provides expert advice and support for all your Executorship duties. Bishop Probate Assistance specialises in providing Probate assistance, Lasting Powers of Attorney, and help for the elderly in both financial and practical affairs.</p>
    <p>Over the past 10 years, I have been a partner at JLM Connects based in Worthing. I am now providing the same service from the heart of Henfield village, where I have lived with my family for nearly 20 years.</p>
    <p>I have over 20 years of experience in the Probate and Finance world. I am passionate about providing a bespoke service, which meets your individual needs. If you have been named as an Executor in a will, or you have lost a loved one, who died without making a will, then you have legal duties. I have expertise in all aspects of being an executor including: - gathering the information needed for the application; record-keeping, correspondence; keeping financial accounts, and much more. I will help with as much or as little as you need. My prices are highly competitive and affordable: avoiding the need for more expensive alternatives.</p>
    <p>Additionally, I provide Powers of Attorney for clients. These important legal documents are crucial as we get older. I will deal with all aspects of their arrangement, allowing you peace of mind and the confidence that future needs can be met. Meetings are either in my office or at your home, whichever is most convenient! Pricing is clear and competitive.</p>
    <p>I am also committed to supporting the elderly. Our rapidly changing world means that older people can feel a little left behind. Currently, I have clients who see me regularly for help with paperwork, technical problems, form filling, and general correspondence, giving complete peace of mind that you, or a loved one, is in safe hands.</p>
    <p>Finally, I have a beautiful golden retriever, who is a PETS therapy dog, called Mavis, who is friendly and adorable. She has already brought smiles and cuddles to many of my clients. If you'd like her to come along to any appointments- just ask!</p>
  </div>
);

export default Home;
