import React, { useState } from 'react';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/contact', formData);
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message.');
    }
  };

  return (
    <div>
      <h1>Contact</h1>
      <br />
      <p>For more information about Bishop Probate Assistance services, contact us today:</p>
      <p>
        <strong>Address:</strong><br />
        18 Staples Barn<br />
        Henfield<br />
        BN5 9PN
      </p>
      <p>
        <strong>Tel:</strong> 07786 080068
      </p>
      <p>
        <strong>Email:</strong><a href="mailto:info@bishopprobateassistance.co.uk"> info@bishopprobateassistance.co.uk</a>
      </p>
      {/*<form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Telephone</label>
          <input type="tel" className="form-control" name="telephone" value={formData.telephone} onChange={handleChange} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Message</label>
          <textarea className="form-control" name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Send</button>
      </form>*/}
    </div>
  );
};

export default Contact;
