import React from 'react';
import lpoa from "../images/Lasting-Power-of-Attorney.jpg"

const Lpoa = () => (
  <div>
    <h1>Lasting Power of Attorney</h1>
    <img src={lpoa} alt="Lasting Power of Attorney" className='float-right img-fluid mr-3 rounded'></img>
    <p>A Lasting power of attorney (LPOA) is an important document that allows you to plan ahead and choose people who could take control of your affairs
       if you become unable or unwilling to manage them yourself.</p>

<h4>Why should I make a lasting power of attorney?</h4>

<p>You never know when you might suffer a serious illness that affects body or mind.
You may be unable to communicate or get out of the house for a while.</p>
<p>You need someone you trust to make decisions for you, if you become unable to make your own.</p>
<p>Anyone over the age of 18 who is of sound mental capacity can make a LPA, it will last for life.</p>
<p>Peace of mind that should something happen you have already secured this important legal document.</p>
<p>If someone is diagnosed with a condition likely to cause loss of communication or mental capacity, they would be well advised to think about who they want to make
 decisions for them when they can no longer do so.</p>
<h4>It's never too soon to set these up, but it can be too late!</h4>

<p>Having an LPA is a safe way of maintaining control over decisions made for you because:
    • It is legally registered with the Office of Public Guardian (OPG) before use.
    • Attorneys are duty bound to follow laid down principles.
    • Your signature, and the signatures of your chosen attorney/s are witnessed</p>

<p>There are two types of Lasting Power of Attorney as follows:</p>

<p>Property and Financial LPOA - someone you trust can act on your behalf in matters such as:
    Day to day money decisions right through to buying and selling property
    Opening, closing, and operating bank/building society accounts
    Claiming and receiving your benefits, pensions, and allowances for you.</p>

<p>Health and Welfare LPOA - someone you trust can act on your behalf in matters such as:
    • Day to day living including diet, medical needs, and longer term care
    • Ensure that your wishes are carried out if it comes to a life or death decision having to be made.</p>

<p>Health and Welfare can only be used by the attorney at the time of mental capacity being lost</p>

<p>Cost - We provide an assisted service for you at an affordable cost and costs include legal registration. We explain everything you need to know. 
  We complete the paperwork mountain for you and follow the process through keeping you informed until all legally completed. Legal registration takes a 
  minimum of 12 weeks.</p>

<p>Appointees/Court of Protection If you lose mental capacity at some point and have not set up an LPA, an appointee or Court of Protection can be 
  applied for in order for someone else to make decisions on your behalf. This process is way more expensive than a Lasting Power of Attorney and 
  waiting time for legal registration from the Court of Protection takes significantly longer.</p>

<p>If you have not got a Lasting power of attorney in place, then please contact me</p>
  </div>
);

export default Lpoa;
